import Layout from "@/layout";
import MLayout from "@/view/mobile/MLayout";

let routes = [];
let u = window.navigator.userAgent;
const routerPC = [
	{
		path: "/",
		redirect: "/Home",
		component: Layout,
		children: [
			{
				path: "/Home",
				name: "首页",
				component: () => import("@/view/newPc/index.vue"),
				// component: () => import("@/view/pc/Home2/index.vue")
			},
			{
				path: "/Detail",
				name: "详情",
				component: () => import("@/view/pc/Home2"),
			},
			{
				path: "/Product/buy",
				name: "商品",
				// component: () => import("@/view/newPc/index.vue")
				component: () => import("@/view/pc/Product/index.vue"),
			},
			{
				path: "/UserInfo",
				name: "个人中心",
				component: () => import("@/view/pc/User"),
			},
			{
				path: "/MyWallet",
				name: "我的钱包",
				component: () => import("@/view/pc/MyWallet"),
			},
			{
				path: "/Mine",
				name: "我的",
				component: () => import("@/view/pc/Mine"),
			},
			{
				path: "/RingDetails",
				name: "硬件详情",
				component: () => import("@/view/pc/RingDetails"),
			},
			{
				path: "/WhiteList",
				name: "白名单",
				component: () => import("@/view/pc/WhiteList"),
			},
			{
				path: "/download",
				name: "下载",
				component: () => import("@/view/pc/Download"),
			},
			{
				path: "/QA",
				name: "常见问题",
				component: () => import("@/view/pc/QA"),
			},
		],
	},
	{
		path: "/404",
		component: () => import("../404.vue"),
	},
];
const routerMB = [
	{
		path: "/",
		redirect: "/mobileHome",
		component: MLayout,
		children: [
			{
				path: "/mobileHome",
				name: "首页",
				component: () => import("@/view/mobile/Home2/index.vue"),
			},
			{
				path: "/Detail",
				name: "详情",
				component: () => import("@/view/mobile/Home2/detail.vue"),
			},
			{
				path: "/mobileProduct",
				name: "product",
				component: () => import("@/view/mobile/Home2/buy.vue"),
			},
			{
				path: "/mobileOrders",
				name: "order",
				component: () => import("@/view/mobile/Home2/order.vue"),
			},
			{
				path: "/mobileInvitation",
				name: "invite",
				component: () => import("@/view/mobile/Home2/invite.vue"),
			},
			{
				path: "/mobileNft",
				name: "NFT",
				component: () => import("@/view/mobile/Home2/nft.vue"),
			},
			{
				path: "/mobileWhiteList",
				name: "白名单任务",
				component: () => import("@/view/mobile/WhiteList/index.vue"),
				// component: () => import("@/view/pc/WhiteList")
			},
			{
				path: "/mobileUser",
				name: "个人中心",
				component: () => import("@/view/mobile/User/index.vue"),
			},
			{
				path: "/mobileRingDetails",
				name: "硬件详情",
				component: () => import("@/view/mobile/RingDetails/index.vue"),
			},
			{
				path: "/download",
				name: "下载",
				component: () => import("@/view/mobile/Download/index.vue"),
			},
			{
				path: "/mobileRelaxRegister",
				name: "注册",
				component: () => import("@/view/mobile/RelaxRegister/index.vue"),
			},
		],
	},
	{
		path: "/404",
		component: () => import("../404.vue"),
	},
];

var isMb = u.match(
	/(phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
); // |iPad

const isWidthMaxHeight = window.innerWidth > window.innerHeight;

routes = isWidthMaxHeight ? routerPC : routerMB;

export { isMb };
export { isWidthMaxHeight };
export default routes;
