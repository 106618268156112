<template>
	<keep-alive>
		<router-view></router-view>
	</keep-alive>
</template>

<script setup>
let width = window.innerWidth;
window.addEventListener("resize", function () {
	if (width !== window.innerWidth) {
		if (window.location.pathname === "/Detail") {
			window.location.href = window.location.href;
		} else {
			window.location.href = window.location.origin;
		}
	}
});
</script>

<style lang="scss" scoped>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin: 0;
}
</style>

<style>
.el-loading-mask {
	background: #20202099 !important;
}
</style>
